import React, { useState, useEffect, useContext, createContext } from 'react';
import { getVariables } from '../config'
const ProyectoContext = createContext();
const url_image=getVariables().URL_IMAGES;



export function useProyecto() {
    return useContext(ProyectoContext);
}

export function ProyectoProvider({ children }) {
    

  const getArea=(proyecto,tipo)=>{
    const superficie = proyecto?.superficieProyectos?.find(s => s?.superficie?.id==tipo);
	  let area = superficie?.area || "";
    if (typeof area === 'number') {
       area = area.toString().replace('.', ',');
    } else if (typeof area === 'string') {
          area = area.replace('.', ',');
    }
    return area;
  }
 
  const getImageProy=(proyecto)=> {
    const galeria = proyecto?.galeriaProyectos?.find((gal) => gal.tipo.id == 1);
    const img = galeria?.imagenes?.find((i) => i.visible === true && i.principal === true);
    return url_image+img?.image;
  }

  const getIconografias=(proyecto)=>{
    const visibleIcons = proyecto?.iconografiaProyectos?.filter((i) => i.visible === true);
    const iconos = visibleIcons?.sort((a, b) => a?.icono.orden - b?.icono.orden); 
    return iconos;
  }

  const getPrecio=(proyecto)=>{
    const prices= proyecto?.currencies?.find(c=>c.precio>0);
	  const precio =prices?.precio || '';
     
     return precio
   
  }

  const getTextoPrecio=(proyecto)=>{
    const prices= proyecto?.currencies?.find(c=>c.precio>0);
	  const precio =prices?.tipotexto || '';
     
     return precio
   
  }

  const getSeparacion=(proyecto)=>{
    const prices= proyecto?.currencies?.find(c=>c.separacion>0);
	  const precio =prices?.separacion || '';
     
     return precio
   
  }
  const getPrice=(proyecto)=>{
      const precio =getPrecio(proyecto);
      const priceLocale = Intl.NumberFormat('es-ES', {
        useGrouping: true,
      });
    

      const priceOutput = priceLocale.format(precio)
     return priceOutput
   
  }

  const getTranslationProy=(proyecto, campo)=>{
   
    const translation = proyecto?.translations?.find((t) => t[campo]);
   
    const valor = translation?.[campo] || "";
    return valor?valor:"";
  }

  const getGalerias=(proyecto)=>{
    const galerias = proyecto?.galeriaProyectos?.filter((i) => i.tipo?.visible === true);  
    const galeriasmacro = galerias?.sort((a, b) => a.id - b.id) || []; 
  
    const proyectos= [];

    let thereIsTours = false;
    galeriasmacro?.forEach((gal) => {
        const tipo = gal.tipo.id;
  
        const existingGroup = proyectos.find(group => group.value === tipo);
        if(tipo=='1'){
          const img = gal?.imagenes?.find((i) => i.principal === false && i.principal_interna===false);
            if(img){
             proyectos.push({ label:gal.tipo.nombre, value:tipo });
          }  

        }else{
          if (!existingGroup) {
              proyectos.push({ label:gal.tipo.nombre, value:tipo });
          }
        }
        if (tipo == 14) thereIsTours = true;
      });
      
      if (proyecto.recorrido && !thereIsTours) {
        proyectos.push({ label: 'Recorrido 360°', value: 'recorrido' });
      }
      if(proyecto.maqueta){
        proyectos.push({ label:'Maqueta', value:'maqueta' });
      }
    
      
    return proyectos;
  }


  const getZonasComunes=(proyecto)=>{
    const zonas = proyecto?.zonaComunProyectos?.filter((i) => i.visible === true);
    const zonacomunes = zonas?.sort((a, b) => a.orden - b.orden) || []; 
  
    const imagenesgal= [];
    if(zonacomunes.length>0){
       
      zonacomunes?.forEach((zona,key) => {
        const images=zona.imagenes.filter(img => img.visible===true);
        const principal=zona.imagenes.find(img => img.visible===true && img.principal===true);
       const orderimages= images?.sort((a, b) => a.orden - b.orden) || []; 
       const imageszona=[]  
       orderimages?.forEach((image,key) => {
        imageszona.push({id :image?.id , img:url_image+image?.image})
       })
         const principa=   principal?.image ? url_image+principal?.image: '';
        imagenesgal.push({ id:zona?.zona?.id ,nombre:zona?.zona?.nombre ,
             image:url_image+zona?.zona?.imagen?.image ,principal:principa, principalid: principal?.id,imageid:zona?.zona?.imagen?.id,
          imagenes:imageszona });
  
        
        });
        
    }
  
    return imagenesgal;

  }

 
  
  const getObras=(proyecto,tipo)=>{
    const obras = proyecto?.galeriaProyectos?.find((i) => i?.tipo.id ==tipo);
     
    const imagenes=obras?.imagenes.filter((i)=>i.visible===true)||[];
    const groupedByFecha= [];

    imagenes?.forEach((imagen,key) => {
      const fechaProyecto = imagen?.fechaProyecto;
      const fechafull=imagen?.fechaFull;

      const existingGroup = groupedByFecha.find(group => group.fechaProyecto === fechaProyecto);

      const image=imagen?.image ? url_image+imagen.image :'';
      const video=imagen?.video ? url_image+imagen.video :'';
      const videoweb=imagen?.videoWeb ? url_image+imagen.videoWeb : '';
      if (existingGroup) {
      
        existingGroup.imagenes.push({ id:key ,img:image, link:imagen?.link, video:video, videoWeb:videoweb, descripcion:getTraduce(imagen,'descripcion') });
      } else {
      
        groupedByFecha.push({ fechaProyecto, fechafull,imagenes: [{ id:key ,img:image,link:imagen?.link,   video:video, videoWeb:videoweb, descripcion:getTraduce(imagen,'descripcion') }] });
      }
    });
    groupedByFecha.sort((a, b) => new Date(b.fechafull) - new Date(a.fechafull));
    console.log({groupedByFecha});
    return groupedByFecha;

  }
 
  const getVideos=(proyecto)=>{
    const galerias = proyecto?.galeriaProyectos?.find((i) => i?.tipo.id ==2);
   
    const videos=galerias?.imagenes.filter(video => video.visible===true)||[];
     const groupvideos= [];
    if(videos.length>0){
        const videosgal=videos?.sort((a, b) => a.orden - b.orden); 
        videosgal?.forEach((item,key) => {
          const video=item?.video ? url_image+item.video :'';
          const videoweb=item?.videoWeb ? url_image+item.videoWeb : '';
          groupvideos.push({ id:key ,link:item?.link, video:video, videoWeb:videoweb });
        
        });
        
    }

    return groupvideos;

  }

  const getImageTipos=(proyecto,tipo)=>{
    const galerias = proyecto?.galeriaProyectos?.find((i) => i?.tipo.id ==tipo);
   
    const imagenes=galerias?.imagenes.filter(image => image.visible===true && !image.principal && !image.principal_interna)||[];
     
    const groupedimage= [];
    if(imagenes.length>0){
        const imagenesgal=imagenes?.sort((a, b) => a.orden - b.orden); 
        imagenesgal?.forEach((imagen,key) => {
          const image=imagen.image ? url_image+imagen.image :''
            groupedimage.push({ id:imagen?.id ,img:image ,link:imagen?.link, descripcion:getTraduce(imagen,'descripcion')})
        });
        
    }

     
  
    return groupedimage;

  }

  
  const getIconografiaInmuebles=(inmueble)=>{
    const visibleIcons = inmueble?.iconografiaInmuebles?.filter((i) => i.visible === true);
    const iconos = visibleIcons?.sort((a, b) => a?.icono.orden - b?.icono.orden); 
    return iconos;
  }

  const getEquipoVenta=(proyecto)=>{
    const visibleEquipos = proyecto?.colaboradores?.filter((i) => i.visible === true);
    const equipos = visibleEquipos?.sort((a, b) => a?.orden - b?.orden); 
    return equipos;
  }

  const getAreaInmueble=(inmueble,tipo)=>{
    const superficie = inmueble?.superficieInmuebles?.find(s => s?.superficie?.id==tipo);
	  let area = superficie?.area || "";
    if (typeof area === 'number') {
      area = area.toString().replace('.', ',');
   } else if (typeof area === 'string') {
         area = area.replace('.', ',');
   }
   return area;
   
  }

  const getAreaValor=(area)=>{
    if (typeof area === 'number') {
      area = area.toString().replace('.', ',');
   } else if (typeof area === 'string') {
         area = area.replace('.', ',');
   }
   return area;
   
  }

  const getMediciones=(med)=>{
    const mediciones = med?.sort((a, b) => a?.orden - b?.orden); 
    return mediciones;
  }

  const getProcessVentas = (dataventa, proyectoIds, key)=>{
    if (!dataventa) {
      console.error('dataventa is null or undefined.');
      return;
    }

    let ventas = [];
    if(key === 'pago'){
      ventas = dataventa.agrupacionesComprador?.filter((a) => 
        validarProyecto(a.idMacroProyecto) && a.planPagosAgrupacion?.some(plan => plan.idConcepto === 1 && (plan.fechaUltimoPago === null || plan.saldo > 1))
      );
    } else if(key === 'historial'){
      ventas = dataventa.agrupacionesComprador?.filter((a) => 
        validarProyecto(a.idMacroProyecto) && a.planPagosAgrupacion?.some(plan => plan.idConcepto === 1)
      );
    } 

    ventas = ventas.map(a => {
      const porPagar = a.planPagosAgrupacion?.filter(plan => plan.idConcepto === 1 && (plan.fechaUltimoPago === null || plan.saldo > 1));
      const historial = a.planPagosAgrupacion?.filter(plan => plan.idConcepto === 1 && plan.fechaUltimoPago !== null && plan.valorPagado > 1);
      return {
          ...a,
          fechaUltimoPagoRelevante: a.planPagosAgrupacion?.find(plan => plan.idConcepto === 0)?.fechaUltimoPago,
          cuotasPorPagar: porPagar?.sort((a, b) => new Date(a.fechaPactada).getTime() - new Date(b.fechaPactada).getTime()),
          historialPago: historial?.sort((a, b) => new Date(a.fechaUltimoPago).getTime() - new Date(b.fechaUltimoPago).getTime()),
      }
    }) || [];

    ventas.sort((a, b) => {
      const fechaA = a.fechaUltimoPagoRelevante ? new Date(a.fechaUltimoPagoRelevante).getTime() : 0;
      const fechaB = b.fechaUltimoPagoRelevante ? new Date(b.fechaUltimoPagoRelevante).getTime() : 0;
      return fechaB - fechaA; 
    });

    const procesadas = ventas.map(a => {
      let infoCuotaActual = {};
      if(a.cuotasPorPagar && a.cuotasPorPagar.length > 0) { 
        let cuotaActual = a.cuotasPorPagar?.find((plan) => plan.fechaUltimoPago === null && plan.diasMora === 0);

        if(!cuotaActual){
          const pagosFaltantes = a.cuotasPorPagar?.filter(plan => plan.fechaUltimoPago === null);
          if(pagosFaltantes) cuotaActual = pagosFaltantes[pagosFaltantes.length - 1];
          else cuotaActual = a.cuotasPorPagar[a.cuotasPorPagar.length - 1];
        }

        const cuotasConMora = a.cuotasPorPagar?.filter(plan => plan.diasMora > 1);
        let saldoMora = cuotasConMora?.reduce((acc, plan) => acc + plan.saldo, 0);
        
        let saldosMora = cuotasConMora?.map(a => ({
          concepto: a.concepto,
          numCuota: a.concepto.match(/\d+/) ? a.concepto.match(/\d+/)[0]: a.concepto,
          saldo: a.saldo
        }));
  
        if(cuotaActual && cuotaActual.diasMora > 1) {
          saldoMora -= cuotaActual.saldo;
          saldosMora = saldosMora?.filter(plan => plan.concepto !== cuotaActual.concepto);
        }

        const valorPactado = cuotaActual.valorPactado - cuotaActual.valorPagado;

        infoCuotaActual = {
          saldosMora,
          saldoPendiente: saldoMora,
          saldoCuota: valorPactado,
          saldoAPagar: saldoMora + valorPactado,
          concepto: cuotaActual.concepto,
          diasMora: cuotaActual.diasMora,
        };
      }

      const cuotas = calcularTotalesPorConcepto(a.planPagosAgrupacion, 1);
      const terceros = calcularTotalesPorConcepto(a.planPagosAgrupacion, 4);
      const subsidios = calcularTotalesPorConcepto(a.planPagosAgrupacion, 6);

      const nombresUnidades = Array.isArray(a.unidadesAgrupacion) 
          ? a.unidadesAgrupacion.map(unidad => unidad.nombre) 
          : [];  
        
      return {
        ...infoCuotaActual,
        cuotas,
        terceros,
        subsidios,
        historialPago: a.historialPago,
        nombre: nombresUnidades[0],
        numeroDeEncargo: a.numeroEncargo,
        idMacro: a.idMacroProyecto,
        idProyecto: a.idProyectoAgrupacion,
        fecha: a.fechaUltimoPagoRelevante
      };
    });

    return procesadas;

    function validarProyecto(proyectoId) {
      return proyectoIds.includes(proyectoId.toString());
    }

    function calcularTotalesPorConcepto(planPagos, idConcepto) {
      const conceptoFiltrado = planPagos.filter(plan => plan.idConcepto === idConcepto);
      const totalPactado = conceptoFiltrado.reduce((acc, value) => acc + value.valorPactado, 0);
      const totalPagado = conceptoFiltrado.reduce((acc, value) => acc + value.valorPagado, 0);
    
      return {
        totalPactado,
        totalPagado,
        totalSaldo: totalPactado - totalPagado
      };
    }
  } 

  const getProcessHistorialVentas = (dataventa)=>{

    if (!dataventa) {
        console.error('dataventa is null or undefined.');
        return;
    }

    const ventas = dataventa.agrupacionesComprador?.filter((a) => 
    a.planPagosAgrupacion?.some((plan) => plan.idConcepto === 1 )
    ).map((a) => ({
        ...a,
        fechaUltimoPagoRelevante: a.planPagosAgrupacion?.find((plan) => plan.idConcepto === 0)?.fechaUltimoPago,
        planPagosAgrupacion: a.planPagosAgrupacion?.filter((plan) => plan.idConcepto === 1 )
    })) || [];

    ventas.sort((a, b) => {
      const fechaA = a.fechaUltimoPagoRelevante ? new Date(a.fechaUltimoPagoRelevante).getTime() : 0;
      const fechaB = b.fechaUltimoPagoRelevante ? new Date(b.fechaUltimoPagoRelevante).getTime() : 0;
      return fechaB - fechaA; 
    });

    const procesadas = ventas.map((a) => {
        const saldoMora = a.planPagosAgrupacion
            .filter((plan) => plan.diasMora > 1)
            .reduce((acc, plan) => acc + plan.saldo, 0);

        const pactados = [...a.planPagosAgrupacion];

        
        const saldoPactado = pactados.reduce((acc, plan) => acc + plan.valorPactado, 0);
        const conceptoPactado = pactados.length > 0 ? pactados[0].concepto : null;

        const nombresUnidades = Array.isArray(a.unidadesAgrupacion) 
            ? a.unidadesAgrupacion.map((unidad) => unidad.nombre) 
            : [];
        
        const cuotasIniciales = a.planPagosAgrupacion.filter((plan) => plan.tipoConceptoPlanPago === "Cuota Inicial");

        const totalValorPactadoInicial = cuotasIniciales.reduce((acc, plan) => acc + plan.valorPactado, 0);
        const totalValorPagadoInicial = cuotasIniciales.reduce((acc, plan) => acc + plan.valorPagado, 0);

        const creditoTerceros = a.planPagosAgrupacion.filter((plan) => plan.tipoConceptoPlanPago === "Credito Terceros");

        const totalValorPactadoTerceros = creditoTerceros.reduce((acc, plan) => acc + plan.valorPactado, 0);
        const totalValorPagadoTerceros = creditoTerceros.reduce((acc, plan) => acc + plan.valorPagado, 0);

        const subsidios = a.planPagosAgrupacion.filter((plan) => plan.tipoConceptoPlanPago === "Subsidio");

        const totalValorPactadoSubsidios = subsidios.reduce((acc, plan) => acc + plan.valorPactado, 0);
        const totalValorPagadoSubsidios = subsidios.reduce((acc, plan) => acc + plan.valorPagado, 0);

        
        return {
            saldoPactado: saldoPactado,
            saldoAPagar: saldoMora + saldoPactado,
            saldoMora: saldoMora,
            nombre: nombresUnidades[0],
            numeroDeEncargo: a.numeroEncargo,
            concepto: conceptoPactado,  
            idMacro: a.idMacroProyecto,
            idProyecto: a.idProyectoAgrupacion,
            cuotaInicialPactado: totalValorPactadoInicial,
            cuotaInicialPagado: totalValorPagadoInicial,
            creditoTercerosPactado: totalValorPactadoTerceros,
            creditoTercerosPagado: totalValorPagadoTerceros,
            subsidiosPactado: totalValorPactadoSubsidios,
            subsidiosPagado: totalValorPagadoSubsidios
        };    
      });
      return procesadas; 
    }



    const ConvertirPrecio=(precio)=>{
      
      const priceLocale = Intl.NumberFormat('es-ES', {
        useGrouping: true,
      });
    

      const priceOutput = priceLocale.format(precio)
     return priceOutput
   
  }
  

  const getTraduce=(tipo, campo)=>{
   
    const translation = tipo?.translations?.find((t) => t[campo]);
   
    const valor = translation?.[campo] || "";
    return valor?valor:"";
  }

  function getGalleryLinks(project, galeryTypeId) {
    const galery = getGalleryItems(project, galeryTypeId);
    const links = [];
    if (galery.length > 0) {
      galery?.forEach((item) => {
        links.push({ url: item?.link, title: removeTags(getTraduce(item, 'descripcion')) });
      });
    }
    return links;
  }

  function removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  function getGalleryItems(project, galeryTypeId) {
    let galery = project?.galeriaProyectos?.find((i) => i?.tipo.id == galeryTypeId);
    galery = galery?.imagenes.filter(item => item.visible === true) || [];
    galery = galery?.sort((a, b) => a.orden - b.orden);
    return galery;
  }


    const value = {
        getArea,
        getImageProy,
        getIconografias,
        getPrice,
        getPrecio,
        getTextoPrecio,
        getSeparacion,
        getEquipoVenta,
        getTranslationProy,
        getObras,
        getZonasComunes,
        getGalerias,
        getImageTipos,
        getVideos,
        getIconografiaInmuebles,
        getAreaInmueble,
        getMediciones,
        getProcessVentas,
        getProcessHistorialVentas,
        ConvertirPrecio,
        getAreaValor,
        getGalleryLinks
       
    }

    return (
        <ProyectoContext.Provider value={value}>
            {children}
            </ProyectoContext.Provider>
    )

}