import { ApiProvider } from '@context/ApiContext';
import { ProyectoProvider } from '@context/ProyectoContext';
import { BrowserRouter } from "react-router-dom";
import { Fragment } from 'react';


export default function ClientWrapper({ children }) {
    return (
        <Fragment>
            <ApiProvider>
                <ProyectoProvider>
                    <BrowserRouter>
                        {children}
                    </BrowserRouter>
                </ProyectoProvider>
            </ApiProvider>
        </Fragment >
    )
}

